#item-stock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 320px;
  max-width: 50vw;
  height: fit-content;
  align-items: left;
  background-color: rgba(231, 231, 224, 0.3);
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #444444;
}

#item-stock-text {
  margin-left: 10px;
}

#item-expiration-text {
  margin-left: 10px;
}

