@use "base";

@media (hover: hover) and (pointer: fine) {
  #navbar-create-item:hover {
    background-color: base.$interaction-color;
    border-color: base.$interaction-color;
  }

  .navbar-item:hover {
    background-color: base.$interaction-color;
  }
}

#navbar-container {
  background-color: base.$background-color;

  display: flex;
  flex-direction: row;

  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.navbar-item {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 5px;
  border-radius: 8px;
  font-size: 14px;

  width: 50px;
  cursor: pointer;
}

.navbar-item-selected {
  background-color: rgba($color: base.$accent-color, $alpha: 0.6);
}

.navbar-item-icon {
  width: 50px;
  height: 50px;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#navbar-pantry {
  background-image: url("../../assets/cupboard.png");
}

#navbar-view-items {
  background-image: url("../../assets/dinner.png");
}

#navbar-create-item-container {
  margin-top: -30px;
}

#navbar-create-item {
  width: 40px;
  height: 40px;

  background: base.$brand-color;
  border: 2px solid base.$brand-color;
  border-radius: 360px;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 40px;
  font-family: Arial;
  box-shadow: 0px 0px 12px grey;
}
