#create-item-container {
  background-color: #fffde4;
  height: 100%;
  display: flex;
  justify-content: center;
}

#create-item-form-container {
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#item-details-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#item-details-prompt {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
  width: 90vw;
}

.item-details-button {
  font-weight: 700;
  margin-top: 10px;
}

.item-details-button-unselected {
  opacity: 50%;
}

.item-details-button-selected {
  opacity: 100%;
}

#create-item-continue-button {
  margin-top: 60px;
}

