@use "base";

#home-page-container {
  background-color: base.$background-color;
}

#hero-container {
  display: flex;
  justify-content: center;
  background-color: base.$brand-color;
  color: white;
}

#hero-info-container {
  display: flex;
  flex-direction: column;
  min-width: 185px;
  text-align: center;
}

#hero-info-container h2 {
  font-size: 24px;
  font-weight: 700;
}

#hero-info-container h3 {
  font-size: 14px;
  font-weight: 400;
}

#hero-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

#hero-logo-container {
  align-items: center;
  flex-direction: column;
  justify-content: center;

  min-width: 150px;
}

#hero-logo {
  width: 100%;
  height: 100%;

  background-image: url("../../assets/money.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#promo-banner-container {
  background-color: base.$brand-color;
}

#promo-banner-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

  background-image: url("../../assets/promo.png");
}

#bottom-action-container {
  flex-direction: column;
  justify-content: center;

  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 60px;

  background-color: base.$background-color;
  text-align: center;
}

#bottom-action-contents {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#bottom-action-contents Button {
  margin-top: 20px;
}

#bottom-action-container h2 {
  font-size: 24px;
  font-weight: 700;
}

#bottom-action-container h3 {
  padding-left: 20px;
  padding-right: 20px;

  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  #hero-container {
    height: 225px;
  }

  #hero-info-container {
    max-width: 185px;
  }

  #hero-logo-container {
    display: flex;
  }

  #promo-banner-container {
    height: 70vw;
  }

  #promo-banner-image {
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  #bottom-action-container {
    display: flex;
  }
}

@media screen and (min-width: 1000px) {
  #home-page-container {
    display: flex;
    flex-direction: column;

    height: 100%;
  }

  #hero-promo-banner-horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    height: 80%;
  }

  #hero-container {
    height: 100%;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex-grow: 1;
  }

  #hero-info-container {
    width: 80%;
  }

  #hero-info-container h2 {
    font-size: 34px !important;
    order: 2;
  }

  #hero-info-container h3 {
    font-size: 24px !important;
    order: 1;
  }

  #hero-button-container #button-container .button-medium {
    font-size: 20px;
    height: 50px;
    width: 150px;
  }

  #hero-logo-container {
    display: none;
  }

  #promo-banner-container {
    width: 700px;
    height: 100%;
    min-height: 500px;
  }

  #promo-banner-image {
    background-size: 600px 500px;
  }
}
@media screen and (min-width: 1550px) {
}
