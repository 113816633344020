@use "base";

#authentication-container {
  background-color: base.$background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#authentication-text {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
