#create-account-container {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fffde4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#create-account-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  padding-top: 20px;
}

#create-account-error-message {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
  width: 90vw;
}

