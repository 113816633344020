#item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-left: 5px;
  padding-right: 5px;
}

#item-parent {
  display: flex;
  align-items: center;
  height: 60px;
  min-width: 320px;
  max-width: 50vw;
  background-color: rgba(231, 231, 224, 0.3);
  flex-grow: 1;
  padding-left: 5px;
  padding-right: 5px;
}

#item-expanded-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 320px;
  max-width: 50vw;
  height: fit-content;
  align-items: left;
  background-color: rgba(231, 231, 224, 0.3);
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #444444;
}

#item-size {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

#item-prices {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.item-vendor-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#item-additional-buttons {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
}

#item-border {
  margin-top: 5px;
  border: thin solid grey;
  background-color: rgba(231, 231, 224, 0.3);
  min-width: 320px;
  max-width: 50vw;
}

