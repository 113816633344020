@use "base";

#login-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: base.$background-color;

  height: 100%;
}

#login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#login-button {
  margin-top: 10px;
  border: none;
  width: 215px;
  height: 50px;
  border-radius: 10px;
  color: white;
  font-weight: 400;
  font-size: 20px;
  background-color: base.$brand-color;
}

#login-error-message {
  display: flex;
  justify-content: center;
  text-align: center;

  margin-bottom: 40px;
  width: 90vw;
}

#create-account {
  margin-top: 20px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  #create-account:hover {
    color: base.$brand-color;
  }
}
