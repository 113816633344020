#view-items-heading {
  text-align: center;
}

#view-items-container {
  background-color: #fffde4;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}

#view-items-elements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fffde4;
  gap: 5px;
}

