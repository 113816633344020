@use "base";

// Only enable hover effects for appropiate devices
@media (hover: hover) and (pointer: fine) {
  .clickable-button:hover {
    background-color: base.$interaction-color;
    border: none;
    color: white;
  }

  .clickable-button-opacity:hover {
    background-color: base.$neutral-color;
    opacity: 100%;
  }
}

.clickable-button {
  cursor: pointer;
  text-decoration: none;
}

.clickable-button-opacity {
  cursor: pointer;
  text-decoration: none;
}

.brand-button {
  background-color: base.$brand-color;
  color: white;
  border: none;
  font-weight: 600;
}

.brand-button-red {
  border: none;
  border-radius: 20px;
  height: 45px;

  background-color: base.$brand-color;
  color: white;
}

.brand-button-green {
  border: none;
  border-radius: 20px;
  height: 45px;

  background-color: base.$accent-color;
  color: white;
}

.brand-button-white {
  border: 2px solid base.$brand-color;
  border-radius: 20px;
  height: 45px;

  background-color: white;
  color: base.$brand-color;
}

.button-small {
  width: 50px;
  font-size: 16px;
}

.button-medium {
  width: 95px;
  font-size: 14px;
}

.button-large {
  width: 215px;
  font-size: 20px;
}

.button-top-margin {
  margin-top: 20px;
}
