#authentication-container {
  background-color: #fffde4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#authentication-text {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

