#item-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

#item-heading-info {
  margin-left: 5px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#item-heading-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-image: url("../../assets/dinner.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#item-heading-info-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#item-heading-name {
  font-weight: 600;
  font-size: 16px;
}

#item-heading-brand {
  font-weight: 600;
  font-size: 15px;
  color: #444444;
}

#item-heading-buttons {
  display: flex;
  align-items: center;
  gap: 5px;
}

#item-heading-add-button {
  border: none;
  width: 50px;
  height: 40px;
  background-color: #ff4646;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

#item-heading-expand-button {
  border: none;
  width: 50px;
  height: 40px;
  background-color: #82bd42;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

