#modal-container {
  border: 1px solid grey;
  position: fixed;
  background-color: #fffde4;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  width: 300px;
  height: 300px;
  padding: 10px;
  z-index: 500;
}

#modal-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

#modal-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#modal-date-picker {
  border: none;
  font-size: 16px;
  display: flex;
  background-color: #d0d1dd;
  border-radius: 15px;
  margin-bottom: 10px;
  height: 45px;
  width: 65%;
  padding-left: 10px;
  justify-content: left;
  align-items: center;
}

#modal-heading {
  flex-grow: 1;
}

#form-field-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/*
#quantity-line {
  display: flex;
  justify-content: space-between;
}

#quantity-field {
  display: flex;

  align-items: flex-start;
}

#quantity-field #form-label {
  width: 55%;
}

#quantity-field #form-text-quantity {
  width: 55%;
  margin-left: 8px;
}

#quantity-field #form-select-container {
  margin-top: 2px;
  width: 10%;
}
*/
#modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

