#item-entry-form-container {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 3px;
  padding-top: 20px;
}

#item-entry-form-parent {
  display: flex;
  justify-content: center;
  width: 90vw;
  padding: 5px;
}

#item-entry-form-buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

#quantity-field {
  display: flex;
  align-items: flex-start;
}

#quantity-field #form-label {
  width: 55%;
}

#quantity-field #form-text-quantity {
  width: 55%;
  margin-left: 8px;
}

#quantity-field #form-select-container {
  margin-top: 2px;
  width: 10%;
}

#serving-field {
  display: flex;
  align-items: flex-start;
}

#serving-field #form-label {
  width: 54%;
}

#serving-field #form-text-serving {
  width: 55%;
  margin-left: 8px;
}

#serving-field #form-select-container {
  margin-top: 2px;
  width: 10%;
}

#item-entry-form-vendor-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.item-entry-form-delete-vendor-button {
  margin-left: 5px;
}

#item-entry-form-add-vendor-button {
  font-size: 23px;
}

.item-entry-form-vendor-price-element {
  display: flex;
  justify-content: right;
  align-items: right;
}

.item-entry-form-vendor-name {
  border: none;
  font-size: 16px;
  display: flex;
  background-color: #d0d1dd;
  border-radius: 15px;
  margin-bottom: 10px;
  height: 45px;
  width: 45%;
  padding-left: 10px;
  justify-content: left;
  align-items: center;
}

.item-entry-form-vendor-price {
  border: none;
  font-size: 16px;
  display: flex;
  background-color: #d0d1dd;
  border-radius: 15px;
  margin-bottom: 10px;
  height: 45px;
  width: 45%;
  padding-left: 10px;
  justify-content: left;
  align-items: center;
  margin-left: 5px;
}

.item-entry-form-field-error {
  border: 3px solid red;
}

