@media (hover: hover) and (pointer: fine) {
  .clickable-button:hover {
    background-color: #ffb01f;
    border: none;
    color: white;
  }
  .clickable-button-opacity:hover {
    background-color: #d0d1dd;
    opacity: 100%;
  }
}
.clickable-button {
  cursor: pointer;
  text-decoration: none;
}

.clickable-button-opacity {
  cursor: pointer;
  text-decoration: none;
}

.brand-button {
  background-color: #ff4646;
  color: white;
  border: none;
  font-weight: 600;
}

.brand-button-red {
  border: none;
  border-radius: 20px;
  height: 45px;
  background-color: #ff4646;
  color: white;
}

.brand-button-green {
  border: none;
  border-radius: 20px;
  height: 45px;
  background-color: #82bd42;
  color: white;
}

.brand-button-white {
  border: 2px solid #ff4646;
  border-radius: 20px;
  height: 45px;
  background-color: white;
  color: #ff4646;
}

.button-small {
  width: 50px;
  font-size: 16px;
}

.button-medium {
  width: 95px;
  font-size: 14px;
}

.button-large {
  width: 215px;
  font-size: 20px;
}

.button-top-margin {
  margin-top: 20px;
}

