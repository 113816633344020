.form-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.form-select {
  font-size: 16px;
  background-color: #d0d1dd;
  border-radius: 15px;
  height: 45px;
  border: none;
  display: flex;
  text-align: center;
  align-items: center;
}

