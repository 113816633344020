.form-container-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-label-vertical {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.form-text-vertical {
  border: none;
  font-size: 16px;
  display: flex;
  background-color: #d0d1dd;
  border-radius: 15px;
  margin-bottom: 10px;
  height: 45px;
  padding-left: 10px;
  justify-content: left;
  align-items: center;
}

.form-container-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.form-label-horizontal {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
  width: 35%;
}

.form-text-horizontal {
  border: none;
  font-size: 16px;
  display: flex;
  background-color: #d0d1dd;
  border-radius: 15px;
  margin-bottom: 10px;
  height: 45px;
  width: 65%;
  padding-left: 10px;
  justify-content: left;
  align-items: center;
}

.field-error {
  border: 3px solid red;
}

